import { IconButton, Tooltip } from "@material-ui/core"
import { IconTrash } from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query"
import { memo, useEffect, useMemo, useState } from "react"
import Select, { components } from "react-select"
import {
  LOGICAL_OPERATORS,
  Operators,
} from "../../../../../../../../../../constants"
import { useAuth } from "../../../../../../../../../../contexts/AuthContext"
import { useDeepCompareCallback } from "../../../../../../../../../../utils/use-deep-compare"
import { apxTheme } from "../../../../../../../../../ApplicationFrame/styling/CustomCSS"
import HStack from "../../../../../../../../../ReusableComponents/HStack"
import ThemedTooltip from "../../../../../../../../../ReusableComponents/ThemedTooltip"
import VStack from "../../../../../../../../../ReusableComponents/VStack"
import {
  getAttributeValuesAPI,
  getEventAttributesAPI,
} from "../../../../../../../../../common/actions"
import { useDateFilters } from "../../../../../../../utils"
import {
  SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE,
  SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_OPERATOR,
  SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_VALUES,
} from "../../../../../constants"
import { useTracked } from "../../../../../store"
import { APP_PLATFORMS } from "../../../../CreateEditCampaignDialog"
import {
  IndicatorSeparator,
  Option,
} from "../../targetaudience/components/selects/DidNotDoEventsComboBox"
import { EVENT_ATTRIBUTES_TO_WHITELIST } from "./EventRow"

export const getLabelForOperator = (opt) => {
  switch (opt) {
    case LOGICAL_OPERATORS.EQ:
      return "="
    case LOGICAL_OPERATORS.NEQ:
      return "!="
    case LOGICAL_OPERATORS.GT:
      return ">"
    case LOGICAL_OPERATORS.GTE:
      return "≥"
    case LOGICAL_OPERATORS.LT:
      return "<"
    case LOGICAL_OPERATORS.LTE:
      return "≤"
    case "REGEX":
      return "REGEX"
    default:
      return ""
  }
}

const SingleValueWithTooltip = (props) => (
  <Tooltip
    title={props.data.label}
    arrow
    placement="top"
  >
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginInline: "auto",
        marginTop: -25,
      }}
    >
      <components.SingleValue {...props} />
    </div>
  </Tooltip>
)

const MultiValueWithTooltip = (props) => (
  <Tooltip
    title={props.data.label}
    arrow
    placement="top-end"
  >
    <div
      style={{
        maxWidth: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <components.MultiValue {...props} />
    </div>
  </Tooltip>
)

const StylesForSelectConditionFeild = {
  control: (base, state) => ({
    ...base,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    width: 125,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    borderRadius: 8,
    border: "none",
    boxShadow: state.isFocused
      ? `0 0 0 1.5px ${state.hasValue ? "#1387E8" : "#D64A40"}`
      : `0 0 0 ${state.hasValue ? "1px #CCD4DA" : "1.5px #D64A40"}`,
    backgroundColor: apxTheme.palette.common.white,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    backgroundColor: "transparent",
  },
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "rgba(0, 40, 69, 0.1)",
    borderRadius: 8,
    padding: "0 6px",
    color: "rgba(0, 40, 69, 0.6)",
  }),
  multiValueRemove: (base) => ({
    ...base,
    borderRadius: "50%",
    color: apxTheme.palette.action.disabled,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "rgba(229, 59, 59, 1)",
    },
  }),
  menu: (base) => ({
    ...base,
    maxWidth: "100%",
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    transform: "scale(1.1)",
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(0, 40, 69, 1)",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    fontSize: 16,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "#D64A40",
    },
  }),
}

export const NewCustomStyles = {
  control: (base, state) => ({
    ...base,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    width: 225,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    borderRadius: 8,
    border: "none",
    boxShadow: state.isFocused
      ? `0 0 0 1.5px ${state.hasValue ? "#1387E8" : "#D64A40"}`
      : `0 0 0 ${state.hasValue ? "1px #CCD4DA" : "1.5px #D64A40"}`,
    backgroundColor: apxTheme.palette.common.white,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    backgroundColor: "transparent",
  },
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "rgba(0, 40, 69, 0.1)",
    borderRadius: 8,
    padding: "0 6px",
    color: "rgba(0, 40, 69, 0.6)",
  }),
  multiValueRemove: (base) => ({
    ...base,
    borderRadius: "50%",
    color: apxTheme.palette.action.disabled,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "rgba(229, 59, 59, 1)",
    },
  }),
  menu: (base) => ({
    ...base,
    maxWidth: "100%",
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    transform: "scale(1.1)",
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(0, 40, 69, 1)",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    fontSize: 16,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "#D64A40",
    },
  }),
}

function NewPropertyRow({
  index,
  appId,
  eventName = "",
  eventType = "",
  property = { name: "", operator: "", value: "" },
  handleOnChange = () => {},
  handleOnDelete = () => {},
  menuPlacement = "bottom",
}) {
  const { name = "", operator = "", value = [] } = property
  const [eventAttributes, setEventAttributes] = useState([])
  const [eventAttributeValues, setEventAttributeValues] = useState([])
  const [attributeValues, setAttributeValues] = useState([])
  const [query, setQuery] = useState("")

  const [state] = useTracked()

  const {
    config: {
      meta: { platform = APP_PLATFORMS.android },
    },
  } = state

  const auth = useAuth()
  const [dateFilters] = useDateFilters(true)
  const queryClient = useQueryClient()

  //session attributes
  const session_attributes = queryClient.getQueryData([
    "attributes",
    auth,
    appId,
  ]).session
  const refinedSessionAttributes = useMemo(() => {
    return session_attributes.filter((item) => {
      return (
        !item.startsWith("apx_") ||
        (item === "apx_device_type" && platform === APP_PLATFORMS.web)
      )
    })
  }, [platform, session_attributes])

  //user attributes
  const user_attributes = queryClient
    .getQueryData(["attributes", auth, appId])
    .user.filter((name) => name.indexOf("apx_") === -1)

  const getEventAttributes = useDeepCompareCallback(
    (name) => {
      getEventAttributesAPI(auth, appId, {
        ...dateFilters,
        of: "users",
        event: name,
      }).then((response) => {
        const filteredValues = response.filter(
          (name) => !EVENT_ATTRIBUTES_TO_WHITELIST.includes(name),
        )
        setEventAttributes(filteredValues)
      })
    },
    [appId, auth, dateFilters],
  )

  const getEventAttributeValues = (attrName, query = null) => {
    getAttributeValuesAPI(auth, auth.appId, {
      ...dateFilters,
      of: "users",
      event: eventName,
      attribute: attrName,
      q: query || "",
    }).then((response) => {
      setEventAttributeValues(response)
    })
  }

  const getAttributeValues = useDeepCompareCallback(
    (value, search = "") => {
      getAttributeValuesAPI(auth, appId, {
        ...dateFilters,
        of: "users",
        attribute: value,
        q: search,
      }).then((response) => {
        const filteredValues = response.filter(
          (name) =>
            ["apx_lt_count", "apx_lt_count_slot"].includes(name) ||
            name.indexOf("apx_") === -1,
        )
        setAttributeValues(filteredValues)
      })
    },
    [appId, auth, dateFilters],
  )

  useEffect(() => {
    getEventAttributes(eventName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName])

  useEffect(() => {
    if (eventType === "event") {
      getEventAttributeValues(name)
    } else {
      getAttributeValues(name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  useEffect(() => {
    if (eventType === "event") {
      getEventAttributeValues(name, query)
    } else {
      getAttributeValues(name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return (
    <HStack
      center
      style={{ marginLeft: 20, borderLeft: "1px solid #C5CDD2" }}
    >
      <div style={{ width: 30, borderBottom: "1px solid #C5CDD2" }}></div>
      <HStack
        justify={"space-between"}
        align={"center"}
        style={{ marginTop: 8 }}
      >
        <HStack
          spacing={10}
          align={"center"}
        >
          {/* Property */}
          <VStack spacing={6}>
            <Select
              menuPlacement={menuPlacement}
              inputId="property"
              placeholder="Select Property"
              isSearchable={true}
              closeMenuOnSelect={true}
              isClearable={true}
              options={
                eventType === "event"
                  ? eventAttributes.map((p) => ({
                      label: p,
                      value: p,
                    }))
                  : eventType === "user"
                    ? refinedSessionAttributes.map((p) => ({
                        label: p,
                        value: p,
                      }))
                    : user_attributes.map((p) => ({
                        label: p,
                        value: p,
                      }))
              }
              isMulti={false}
              onChange={(pair) => {
                const data = {
                  type: SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE,
                  value: pair?.value || "",
                }
                handleOnChange(data)
              }}
              value={name === "" ? "" : { label: name, value: name }}
              styles={{
                ...NewCustomStyles,
              }}
              components={{
                Option,
                IndicatorSeparator,
                SingleValue: SingleValueWithTooltip,
              }}
            />
          </VStack>

          {/* Condition and Value */}
          {name !== "" && (
            <>
              <VStack spacing={6}>
                <Select
                  menuPlacement={menuPlacement}
                  isDisabled={false}
                  inputId="Condition"
                  placeholder="Condition"
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  isClearable={true}
                  options={Operators}
                  isMulti={false}
                  onChange={(pair) => {
                    const data = {
                      type: SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_OPERATOR,
                      value: pair?.value || "",
                    }
                    handleOnChange(data)
                  }}
                  value={
                    operator === ""
                      ? ""
                      : {
                          label: getLabelForOperator(operator),
                          value: operator,
                        }
                  }
                  styles={{
                    ...StylesForSelectConditionFeild,
                  }}
                  components={{
                    Option,
                    IndicatorSeparator,
                  }}
                />
              </VStack>

              <VStack spacing={6}>
                <Select
                  menuPlacement={menuPlacement}
                  isDisabled={false}
                  inputId="value"
                  placeholder="Property value"
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  isClearable={true}
                  options={
                    eventType === "event"
                      ? eventAttributeValues.map((p) => ({
                          label: p,
                          value: p,
                        }))
                      : attributeValues.map((p) => ({
                          label: p,
                          value: p,
                        }))
                  }
                  isMulti={true}
                  onChange={(pair) => {
                    const data = {
                      type: SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_VALUES,
                      value: pair !== null ? pair.map((p) => p.value) : [],
                    }
                    handleOnChange(data)
                  }}
                  value={value.map((p) => ({
                    label: p,
                    value: p,
                  }))}
                  styles={{
                    ...NewCustomStyles,
                  }}
                  components={{
                    Option,
                    IndicatorSeparator,
                    MultiValue: MultiValueWithTooltip,
                  }}
                  onInputChange={(q) => {
                    setQuery(q)
                  }}
                />
              </VStack>
            </>
          )}
          {/* Remove Filter */}
          <VStack>
            <ThemedTooltip
              id={"attribute-filter-remove-" + index}
              title="Remove Filter"
              dark
            >
              <IconButton
                size="small"
                aria-label="Delete"
                style={{
                  marginBottom: "6px",
                }}
                onClick={() => {
                  handleOnDelete()
                }}
              >
                <IconTrash size={"20px"} />
              </IconButton>
            </ThemedTooltip>
          </VStack>
        </HStack>
      </HStack>
    </HStack>
  )
}

export default memo(NewPropertyRow)
