import { Button } from "@material-ui/core"
import { useQueryClient } from "@tanstack/react-query"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { useAuth } from "../../../../../../../../contexts/AuthContext"
import generateShortID from "../../../../../../../../utils"
import { useTrackedState } from "../../../../../../../IntegrationExperience/store"
import ColumnDiv from "../../../../../../../ReusableComponents/ColumnDiv"
import VStack from "../../../../../../../ReusableComponents/VStack"
import { SET_TERMINATE_INFO_ENABLE_GOAL_EVENTS } from "../../../../../../components/walkthroughs/components/design/constants"
import { useDateFilters } from "../../../../../utils"
import {
  APP_PLATFORMS,
  SET_SNACKBAR_ERROR_MESSAGE,
  SET_TERMINATE_INFO_ADD_GOAL_EVENT,
  SET_TERMINATE_INFO_GOAL_EVENT_EVENTS_OPERATOR,
  SET_TERMINATE_INFO_GOAL_EVENT_REMOVE_EVENT,
  SET_TERMINATE_INFO_UPDATE_GOAL_EVENTS,
} from "../../../constants"
import { useTracked } from "../../../store"
import EventRow from "./NewContextBuilder/components/EventRow"

const SURVEY_EXCLUSION_EVENTS = [
  "apx_survey_question_asked",
  "apx_survey_question_answered",
  "apx_back_button_clicked",
]

const SURVEY_INCLUSION_EVENTS = [
  "apx_survey_close_button_clicked",
  "apx_survey_response",
]

export default function SurveyStopConditionsEventBuilder() {
  const [state, dispatch] = useTracked()
  const {
    config: {
      terminate_info: {
        goal_events: { events },
        enable_goal_events,
      },
      meta: { config_type } = { config_type: "walkthrough" },
      surveyType = "latest",
      ui = {},
    },
  } = state

  const {
    app_data = {
      features: [],
      basic_info: { platform: APP_PLATFORMS.android },
    },
  } = useTrackedState()

  const {
    features = [],
    basic_info: { platform = APP_PLATFORMS.android } = {
      platform: APP_PLATFORMS.android,
    },
  } = app_data

  const auth = useAuth()
  const { appId } = useParams()
  const [dateFilters] = useDateFilters(true)

  const queryClient = useQueryClient()

  const appEvents =
    queryClient.getQueryData(["app-events", auth, appId, dateFilters]) ?? []

  const isLatestSurvey = useMemo(
    () => config_type === "survey" && surveyType === "latest",
    [config_type, surveyType],
  )

  const { survey: { q_and_a = [] } = {} } = ui || { survey: {} }

  const data = {
    _id: generateShortID(),
    event_type: "app_event",
    context_rule_type: "APP",
    type: "did",
    sequence: -1,
    time_bounds: {
      lower: 0,
      upper: 6000000,
    },
    count_config: {
      count: 1,
      operator: "GTE",
    },
    combine_operator: "OR",
    activity: "",
    details: {
      additional_info: {},
      name: "",
    },
    trigger: {
      event_type: "app_start",
      activity: "",
      details: {
        name: "",
        additional_info: {},
      },
      time_bounds: {
        lower: 0,
        upper: 60000000,
      },
    },
  }

  // Filtering some events for surveys
  // Filtering inclusion events because, we add anyways, to avoid duplication we are filtering before.
  const options = appEvents
    .filter(
      (event) =>
        !SURVEY_EXCLUSION_EVENTS.includes(event) &&
        !SURVEY_INCLUSION_EVENTS.includes(event) &&
        !event.includes("apx_survey"),
    )
    .concat(SURVEY_INCLUSION_EVENTS)

  return (
    <VStack>
      <ColumnDiv
        styles={{
          borderLeft: "1px solid #C5CDD2",
          marginLeft: events.length > 0 ? 15 : 0,
          paddingTop: events.length > 0 ? 20 : 0,
          paddingBottom: events.length > 0 ? 10 : 0,
        }}
      >
        {events.length > 0 &&
          events.map((condition, index) => (
            <EventRow
              key={condition._id}
              appId={appId}
              conditions={events}
              condition={condition}
              index={index}
              dispatch={dispatch}
              combineOperator={condition.combine_operator}
              sequenceEnabled={condition.sequence_enabled}
              updateOperator={(value) => {
                dispatch({
                  type: SET_TERMINATE_INFO_GOAL_EVENT_EVENTS_OPERATOR,
                  value,
                })
              }}
              platform={platform}
              features={features ?? []}
              handleOnChange={(data) => {
                dispatch({
                  type: SET_TERMINATE_INFO_UPDATE_GOAL_EVENTS,
                  index,
                  value: data,
                })
              }}
              handleOnDelete={(value) => {
                dispatch({
                  type: SET_TERMINATE_INFO_GOAL_EVENT_REMOVE_EVENT,
                  index,
                })
              }}
              appEvents={options}
              appScreenNames={[]}
              customScreenNames={[]}
              clientEvents={[]}
              isLatestSurvey={isLatestSurvey}
              surveyQandA={q_and_a}
              isForGoalEventStopConditions={true}
            />
          ))}
      </ColumnDiv>
      <div>
        <Button
          id="add-event-button"
          variant="contained"
          color="primary"
          disabled={false}
          style={{ marginTop: 10 }}
          onClick={() => {
            const cannotAdd = options.length < 1

            dispatch({
              type: SET_SNACKBAR_ERROR_MESSAGE,
              value: cannotAdd ? "No events left to add" : null,
            })
            if (cannotAdd) {
              return
            }
            if (!enable_goal_events) {
              dispatch({
                type: SET_TERMINATE_INFO_ENABLE_GOAL_EVENTS,
                enabled: true,
              })
            }
            dispatch({
              type: SET_TERMINATE_INFO_ADD_GOAL_EVENT,
              payload: [
                ...events,
                events.length > 0
                  ? { ...data, combine_operator: events[0].combine_operator }
                  : data,
              ],
            })
          }}
        >
          {events.length > 0 ? "+ Add another event" : "Add Event"}
        </Button>
      </div>
    </VStack>
  )
}
